import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

interface IProps {}

const Contact = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [values, setValues] = React.useState([
    {
      title: fm({ id: "values-1" }),
      desc: fm({ id: "values-1-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
  ]);
  return (
    <>
      <SEO title={fm({ id: "menu-contact" })} />
      <Layout>
        <div>
          <div className="relative z-40">
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
              <StaticImage
                src="../images/contact us.png"
                alt=""
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div>
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div>
            <div className="container relative mx-auto px-4 h-screen flex items-center justify-center text-white">
              <p
                className="text-xl font-cool w-full text-center lg:w-2/3 lg:text-5xl text-white"
                data-sal="slide-up"
                data-dal-delay="1000"
                data-sal-duration={2000}
              >
                {fm({ id: "contact-title" })}
              </p>
            </div>
          </div>
          <div className="min-h-screen w-full overflow-hidden font-cool flex flex-col relative justify-center bg-white">
            {/* <div className="bg-c1 bg-opacity-50 w-screen transform translate-x-3/4 h-screen  rounded-full absolute right-0 bottom-0 top-0"></div> */}
            <div className="bg-c1 bg-opacity-50 w-full lg:w-screen transform lg:-translate-x-3/4 h-screen  rounded-full absolute left-0 bottom-0 top-0"></div>

            <div className="container px-4 relative mx-auto">
              <div className="flex flex-col lg:flex-row items-center">
                <div className="w-2/12 hidden lg:block text-center">
                  <div className="inline-flex">
                    <div className="border-r-8 mx-auto bg-opacity-50 border-main border-opacity-50 w-1 mr-1 h-screen"></div>
                    <div className="border-r-8 mx-auto bg-opacity-50 border-main border-opacity-80 w-1 mr-1 h-screen"></div>
                    <div className="border-r-8 mx-auto bg-opacity-50 border-main border-opacity-100 w-1 mr-1 h-screen"></div>
                  </div>
                </div>
                <p
                  className="w-full lg:w-6/12 text-xl"
                  data-sal="slide-up"
                  data-dal-delay="1000"
                  data-sal-duration={2000}
                >
                  <h3 className="text-main text-3xl">
                    {fm({ id: "contact-address-label" })}
                  </h3>
                  <p className="text-xl font-body text-gray-600 my-5 mb-10">
                    {fm({ id: "contact-address" })}
                  </p>
                  <h3 className="text-main text-3xl">
                    {fm({ id: "contact-phone" })}
                  </h3>
                  <p className="text-xl font-body text-gray-600 my-5 mb-10">
                    (+976) 7505-9911
                  </p>
                  <h3 className="text-main text-3xl">
                    {fm({ id: "contact-email" })}
                  </h3>
                  <p className="text-xl font-body text-gray-600 my-5 mb-10">
                    info@steppeholding.mn
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Contact;
