import * as React from "react";
import Header from "../Header";
import Footer from "../Footer";

interface IProps {
  children?: any;
  inverse?: boolean;
}

const Layout = (props: IProps) => {
  return (
    <>
      <Header inverse={props?.inverse} />
      {props.children}
      <Footer isHome={false} />
    </>
  );
};

export default Layout;
